<template>
  <div>
    <div class="modal-title-match">
      {{ $t('views.bookings.index.match-modal.information') }}
    </div>
    <label class="modal-label">
      {{ $t('views.bookings.index.match-modal.match-name') }}
    </label>
    <d-text-field
      v-model="match.name"
      :rules="validation.name"
      size="sm"
      error-text="validation.text-input-three-characters"
    />
    <label class="modal-label">
      {{ $t('views.bookings.index.match-modal.match-description') }}
    </label>
    <b-textarea
      v-model="match.description"
      rows="5"
      size="sm"
      no-resize
    >
    </b-textarea>
    <div class="modal-title-match mt-3">
      {{ $t("views.bookings.index.match-modal.match-levels") }}
    </div>
    <div class="modal-label">
      {{ $t('views.bookings.index.match-modal.select-minimum-level') }}
    </div>
      <levels
        :booking="booking"
        :activity-levels="match.activityLevels"
        type="min"
      />
    <label class="modal-label">
      {{ $t('views.bookings.index.match-modal.select-maximum-level') }}
    </label>
    <div>
      <levels
        :booking="booking"
        :activity-levels="match.activityLevels"
        type="max"
      />
    </div>
    <div class="d-flex mt-5">
      <div>
        <vs-checkbox
          v-model="match.activityLevelRequired"
          color="primary"
          value="true"
        >
        </vs-checkbox>
      </div>
      <div>
        <label class="modal-title-match">
          {{ $t('views.bookings.index.match-modal.limit-level') }}
        </label>
        <div>
          <label class="modal-text-limit">
            {{ $t('views.bookings.index.match-modal.limit-text') }}
          </label>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  components: {
    Levels: () => import("@custom/match/Levels")
  },
  data: () => ({
    validation: require('@validation/entities/doinsport/Match.json'),
  }),
  props: {
    match: {
      type: Object,
      default: null
    },
    booking: {
      type: Object,
      default: null
    }
  },
  created() {
    this.$vs.theme({
      primary: '#0B2772',
    });
  },
  beforeMount() {
    this.$store.dispatch('validator/set', this.validation);
  },
}
</script>
<style scoped lang="scss" src="@lazy/match/_match-form.scss"/>
